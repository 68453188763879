import React from "react";
import { ConfigProvider, theme as antdTheme } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import { useTheme } from "../ThemeContext/ThemeContext";

export const AntdTheme = ({ children }) => {
  const { darkMode, theme } = useTheme();

  const antdColors = {
    colorPrimary: theme.primary,
    colorBgContainer: theme.backgroundLight,
    colorText: theme.text,
    colorTextSecondary: theme.textLight,
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm,
        token: antdColors,
        components: {
          Splitter: {
            splitBarDraggableSize: 40,
          },
        },
      }}
    >
      <StyleProvider hashPriority="high">{children}</StyleProvider>
    </ConfigProvider>
  );
};