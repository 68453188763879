import * as z from "zod"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const source_taskDbModel = z.object({
  sync_id: z.string(),
  task_id: z.string(),
  package: z.string(),
  version: z.string(),
  started_at: z.coerce.date(),
  updated_at: z.coerce.date(),
  started_by: jsonSchema,
  status: z.string(),
  description: z.string().nullish(),
  metrics: jsonSchema,
  error: z.string().nullish(),
})
