import * as z from "zod"

export const NotificationDbModel = z.object({
  id: z.string(),
  workspaceId: z.string(),
  actorId: z.string(),
  tableName: z.string(),
  channelId: z.string(),
  statusChangeId: z.bigint(),
  timestamp: z.coerce.date(),
  status: z.string(),
  error: z.string().nullish(),
})
