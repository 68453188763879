import * as z from "zod"

export const NotificationStateDbModel = z.object({
  workspaceId: z.string(),
  actorId: z.string(),
  tableName: z.string(),
  channelId: z.string(),
  flappingSince: z.coerce.date().nullish(),
  lastNotification: z.coerce.date(),
  statusChangeId: z.bigint(),
  error: z.string().nullish(),
})
